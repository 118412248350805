@label-percent: 160px;
@small-percent: 0px;
@vertical-gap: 6px;
@items-gap: 20px;

ul.ant-timeline {
  margin-top: calc(-1 * (0.2em + (@items-gap / 2)));

  .ant-timeline-item-head {
    width: 10px;
    height: 10px;
    top: -1px;
    border-color: @text-color;
    background-color: transparent;
  }

  .ant-timeline-item-tail {
    top: 10px;
    border-left: 2px dotted @secondary-color;
  }

  .ant-timeline-item-last > .ant-timeline-item-tail {
    display: block;
  }

  &.ant-timeline-label .ant-timeline-item-label {
    width: calc(@label-percent - 20px);
    white-space: nowrap;

    span:not(:first-child)::before {
      content: " - ";
    }

    @media only screen and (max-width: @tablet-screen) {
      text-align: right;
      padding: 0 26px;
      width: 100%;

      div,
      div:not(:first-child)::before {
        display: block;
      }
    }
  }

  &.ant-timeline-alternate .ant-timeline-item-tail,
  &.ant-timeline-right .ant-timeline-item-tail,
  &.ant-timeline-label .ant-timeline-item-tail,
  &.ant-timeline-alternate .ant-timeline-item-head,
  &.ant-timeline-right .ant-timeline-item-head,
  &.ant-timeline-label .ant-timeline-item-head,
  &.ant-timeline-alternate .ant-timeline-item-head-custom,
  &.ant-timeline-right .ant-timeline-item-head-custom,
  &.ant-timeline-label .ant-timeline-item-head-custom {
    left: @label-percent;

    @media only screen and (max-width: @tablet-screen) {
      right: @small-percent;
      left: calc(100% - @small-percent);
    }
  }

  &.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
  &.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
  &.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    left: calc(@label-percent - 4px);
    width: calc(95% - @label-percent);

    @media only screen and (max-width: @tablet-screen) {
      left: @small-percent;
      width: 90%;
      margin-left: 0;
    }
  }

  li.ant-timeline-item {
    padding-bottom: @items-gap;

    div.ant-timeline-item-content {
      p {
        margin: 0;
      }

      @media only screen and (max-width: @tablet-screen) {
        padding-top: @normal-text + 5px;
      }
    }
    @media only screen and (max-width: @tablet-screen) {
      &:first-child {
        padding: 0 !important;
      }
      div.ant-timeline-item-content {
        padding-top: @normal-text + 15px;
      }
    }
  }

  li.ant-timeline-item:last-child {
    padding: 0;
  }
}
