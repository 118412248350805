.technologies {
  .ant-image {
    margin: 25px;
  }

  .skill-logo {
    max-height: 50px;
    filter: grayscale(100%) invert(40%);
  }

  .ant-divider-horizontal.ant-divider-with-text {
    padding: 0 20%;
  }
}
