@fontName: "Poppins";
@fontWeights: "Thin", "Light", "Regular", "Medium", "SemiBold", "Bold", "Black";
@fontWeightsNum: 200, 300, 400, 500, 600, 700, 800;

.fontFace(@indexPrefix: 1) when (@indexPrefix =< length(@fontWeights)) {
  @fontWeight: extract(@fontWeights, @indexPrefix);
  @fontWeightNum: extract(@fontWeightsNum, @indexPrefix);

  @fontFullName: "@{fontName}-@{fontWeight}";
  @fileName: "../../../assets/fonts/@{fontName}/@{fontFullName}";
  @font-face {
    font-family: @fontName;
    font-weight: @fontWeightNum;
    font-style: normal;
    font-display: swap;
    src: local("@{fontFullName}"), url("@{fileName}.ttf") format("truetype"),
      url("@{fileName}.ttf") format("truetype");
  }
  .fontFace(@indexPrefix + 1);
}

.fontFace();
@f: "@{fontName}", "Helvetica Neue", sans-serif;

body {
  margin: 0 auto;
  // width: 100vw;
  height: 100vh;
  font-family: Poppins;
  background-color: @background-color;
  color: @text-color;
}
