.homepage {
  .stage {
    width: 100%;
    height: calc(100vh - 200px);

    div {
      font-size: 90px;

      .static-text {
        margin: 0;
        padding: 0;
        font-weight: bold;
        font-size: 150px;
        line-height: 1;

        @media only screen and (max-width: @tablet-screen) {
          font-size: 20vw;
        }
      }

      .typing-text {
        margin: 0;
        padding: 0;
        font-weight: bold;

        > span {
          overflow-wrap: break-word;
          word-wrap: break-word;

          > div {
            display: inline !important;
          }
        }
      }

      @media only screen and (max-width: @desktop-screen) {
        font-size: 5vw;
      }

      // Desktops
      @media only screen and (min-width: @desktop-screen + 1) {
        font-size: 70px;
      }
    }
  }
}
