.hcf-layout {
  background-color: transparent;

  > * > div {
    margin: 0 auto;
    max-width: @desktop-screen;
    padding: 0 10px;
    height: 100%;
    width: 100%;
  }

  .hcf-header {
    background-color: transparent;
    padding: 0 10px;

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .hcf-menu {
        width: 100vw;
        float: right;
        border-bottom: 0;
        justify-content: flex-end;
        background-color: transparent;

        @media only screen and (max-width: @tablet-screen) {
          display: none;
        }
      }

      .hcf-expander {
        display: none;
        // background: #00000066;
        border-radius: 10px;

        * {
          color: @text-color;
        }

        @media only screen and (max-width: @tablet-screen) {
          display: block;
        }
      }
    }

    @media only screen and (max-width: @tablet-screen) {
      width: 100%;

      &.scrolled {
        background-color: @background-color;
        box-shadow: #00000022 0px 4px 4px 0px;
        position: fixed;
        top: 0;
        z-index: 999;
        transition: all 500ms cubic-bezier(0.55, 0, 0.1, 1);
      }
    }
  }

  .hcf-conntent {
    padding: 0 10px;

    .title {
      &-lv1 {
        font-size: @title-lv1;
        margin-bottom: 0.4em;
      }
      &-lv2 {
        font-size: @title-lv2;
        margin-bottom: 0.3em;
      }
      &-lv3 {
        font-size: @title-lv3;
        margin-bottom: 0.2em;
      }
      &-lv4 {
        font-size: @title-lv4;
        margin-bottom: 0.1em;
      }
    }
  }

  .hcf-footer {
    background-color: transparent;
    height: 100px;
    padding: 0 10px;
  }
}

.container {
  width: 100%;
}
