.ant-drawer-content-wrapper {
  .ant-drawer-content {
    .ant-drawer-wrapper-body {
      .ant-drawer-body {
        padding: 24px 0;

        .ant-menu-root.ant-menu-vertical,
        .ant-menu-root.ant-menu-vertical-left,
        .ant-menu-root.ant-menu-vertical-right,
        .ant-menu-root.ant-menu-inline {
          background: transparent;
        }

        .ant-menu-inline,
        .ant-menu-vertical,
        .ant-menu-vertical-left {
          border-right: none;
        }
      }
    }
  }
}
